<template>
  <div class="header">
    <div class="logo">
        <img :src="logo" alt="">
        <div class="title-text">抖客分账</div>
    </div>
    <div class="menu">
      <el-anchor :offset="120" direction="horizontal">
        <el-space :size="50">
          <el-anchor-link href="#home">
            首页
          </el-anchor-link>
          <el-anchor-link href="#about">
            关于我们
          </el-anchor-link>
          <el-anchor-link href="#platform">
            已接入平台
          </el-anchor-link>
          <el-anchor-link href="#cooperative">
            合作伙伴
          </el-anchor-link>
          <el-anchor-link href="#contact">
            联系我们
          </el-anchor-link>
        </el-space>
      </el-anchor>
    </div>
    <div class="login">
      <el-button type="primary" plain round @click="toLogin">登 录</el-button>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/logo.png'
export default {
  name: "header-component",
  data() {
    return {
        logo
    }
  },
  methods: {
    toLogin() {
        window.open('https://console.sumou.fun/#/login', '_blank');
    }
  }
};
</script>

<style scoped lang="less">
:deep(.el-anchor) {
    background: transparent;
    .el-anchor__item {
        .el-anchor__link {
            font-size: 20px !important;
            color: #000;
        }
    }
}
.header {
    display: flex;
    height: 100%;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    box-sizing: border-box;
    .logo {
      height: 48px;
      display: flex;
      align-items: center;
      img {
        height: 100%;
      }
      .title-text {
        white-space: nowrap;
        padding-left: 16px;
        font-size: 24px;
        font-weight: 700;
      }
    }
    .menu {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login {
        width: 236px;
    }
}
</style>